import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo
      title="Home"
      description={`Professional Jamstack and Mobile App Development`}
    />
    <div
      className={
        "landingpage w-screen flex items-center justify-center lg:items-end lg:justify-end h-screen"
      }
    >
      <div className={"text-center lg:pb-16 lg:pr-16 "}>
        <h1 className={"text-4xl mb-8 transform -skew-y-6"}>
          {`{`} All Class Web {`}`}
        </h1>
        <p className={'mb-4 transform -skew-y-6'}>Professional Jamstack and Mobile App Development.</p>
        <p className={'transform -skew-y-6'}>Website coming soon.</p>
      </div>
    </div>
    {/* <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    /> */}
    <p>
      <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
    </p>
  </Layout>
)

export default IndexPage
